.list {
  @extend .well;
  padding: 0;
  overflow: hidden;

  .list-header {
    float: left;
    padding: 5px 15px;
    border-radius: 4px 4px 0 0;
    h2 {
      font-size: 18px;
      a { color: $textColor; }
      i { position: relative; top: 4px; }
    }
    img {
      position: relative;
      top: -2px;
      margin-right: 2px;
    }
  }

  .list-actions {
    float: right;
    padding: 9px 12px 9px 0;
    a {
      margin-left: 7px;
    }
    form {
      margin: 0 0 0 10px;
    }
  }

  .list-filter {
    clear: both;
    background-color: #ccc;
    padding: 6px 14px 4px 14px;
    a {
      text-decoration: none;
      display: inline-block;
    }
    .label {
      margin-left: 2px;
      margin-bottom: 1px;
    }
  }

  .advanced-list-filter {
    display: none;
    &.active { display: block; }
    background: #fff;
    padding-top: 20px;

    .form-actions {
      background: #fff;
      padding-left: 160px;
      padding-top: 10px;
      margin-top: 0;
      border: 0;
    }

    .date_range {
      .controls {
        input.date {
          width: 184px;
        }
      }
    }

    .input-sep { margin: 0 10px; }

    form.simple_form {
      .form-inputs {
        @include clearfix;

        .col {
          float: left;
          width: 49%;
        }
      }
    }
  }

  .list-data {
    clear: both;
    border-top: 1px solid #e9e9e9;

    div.contents {
      border-top: 1px solid #e9e9e9;
      padding: 10px 15px;
    }

    table {
      width: 100%;
      th, td {
        padding: 5px 0 5px 5px;
        background: #fff;
        border-top: 1px solid #e9e9e9;
        vertical-align: top;
      }
      thead th {
        text-align: left;
      }
      td.actions {
        text-align: right;
        padding-right: 15px;
        vertical-align: middle;
      }
      .danger {
        color: $router-light-red;
      }
      .separator td {
        background-color: $light-grey;
      }
    }

    ul {
      list-style: none;
      border-top: 1px solid #e9e9e9;
      margin: 0;
      padding: 10px 0;
      li {
        padding: 5px 15px;
      }
    }
  }
}
