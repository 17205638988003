$light-grey:            #F7F7F7;
$grey:                  #D4D4D4;
$medium-grey:           #B4B4B4;
$dark-grey:             #666666;
$bordo:                 #8F1A42;
$green:                 #18B839;
$dark:                  #454544;
$black:                 #000000;

$router-dark-grey:      #4D4D4D;
$router-grey:           #969696;
$router-light-grey:     #AEB3B3;
$router-red:            #8A0300;
$router-light-red:      #D02000;
$router-purple:         #730040;
$router-light-purple:   #9F0059;
