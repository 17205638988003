.form-inner-title {
  padding: 9px 20px 2px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}

input.time-component {
  width: 75px;
  float: left;
}

input.date-component {
  width: 117px;
  float: left;
}

.corrections-form-horizontal .control-label {
  width: 220px;
}

.corrections-form-horizontal .controls {
  margin-left: 240px;
}

.new-transaction-form-horizontal .control-label {
  width: 260px;
}

.new-transaction-form-horizontal .controls {
  margin-left: 280px;
}

.select2-ajax-input {
  width: 206px;
}

.select2-ajax-input-x2 {
  width: 440px;
}
