body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
  background-color: white;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.btn {
  font-size: 13px;
}

.label,
.badge {
  font-size: 10px;
}

.nav-pills > .green-pill > a, .nav-pills > .green-pill > a:hover, .nav-pills > .green-pill > a:focus {
    color: white;
    background-color: #468847;
}
