.display-none {
  display: none
}

.visibility-hidden {
  visibility: hidden
}

.color-red {
  color: $router-red;
}

.color-green {
  color: $green;
}

.color-grey {
  color: $grey;
}

.font-style-italic {
  font-style: italic;
}

.float-right {
  float: right;
}

a.undecorate {
  text-decoration: none;
}

div.code {
  font-family: Consolas, monospace;
  white-space: pre;
}
