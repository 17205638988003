@import "bootstrap-sass/lib/variables";
@import "bootstrap-sass/lib/mixins";
@import "variables";
// .css removed so we can extend CSS classes
@import "bootstrap-sass/bootstrap-2.3.2";
@import "bootstrap-sass/bootstrap-responsive-2.3.2";
@import "RailsStyles/vendor_overrides/bootstrap";

@import "RailsStyles/list";
@import "RailsStyles/helpers";
@import "RailsStyles/labels";
@import "RailsStyles/tables";
@import "RailsStyles/forms";

@import 'jquery-ui/themes/base/core.css';
@import 'jquery-ui/themes/base/datepicker.css';
@import 'jquery-ui/themes/base/theme.css';

@import "select2/select2.css";
@import "../vendor/select2-bootstrap.scss";

@import "../vendor/tipsy.css";

.navbar .mini-brand {
  font-size: 13px;
}

@media (min-width: 980px) {
  body {
    padding-top: 60px;
  }
}

hr {
  margin: 10px 0;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 14px;
}

.search {
  button span {
    display: none;
  }
  .input-append {
    margin-bottom: 0;
  }
}

.new-trx-overview-well {
  @include clearfix;
}
